<template>
  <div>
    <HeaderPanel
      title="Info"
      :filter="filter"
      placeholder=""
      :hasDropdown="false"
      :hasFilter="false"
      :hasSearch="false"
    />
    <div class="title-tabs mt-3">INFO DETAIL</div>
    <div class="bg-white p-3">
      <template v-if="id == 3">
        <div>
          <div>Customer (Identify) (Register/Update)</div>
          <div>URL : https://api.segment.io/v1/identify</div>
          <div>Method : POST</div>
          <pre class="mt-3">
            {
              userId: {{ user_id }},
              context: {
                ip: "24.5.68.47",
              },
              timestamp: "2012-12-02T00:30:12.984Z",
              traits: {
                brand_name: {{ brand_name }},
                first_name: {{ var2 }},
                last_name: {{ var3 }},
                email: {{ var5 }},
                phone: {{ var10 }},
                birthday: "2023-01-18T00:00:00.000Z",
                registerdate: "2023-01-18T00:00:00.000Z",
                updatedate: "2023-01-18T00:00:00.000Z",
              },
              type: "identify",
            }
          </pre>
        </div>
        <div>
          <div>Register (Track)</div>
          <div>URL : https://api.segment.io/v1/track</div>
          <div>Method : POST</div>
          <pre class="mt-3">
            {
              userId: {{ user_id }},
              context: {
                ip: "24.5.68.47",
              },
              timestamp: "2012-12-02T00:30:12.984Z",
              event: "Registration",
              properties: {
                first_name: {{ var2 }},
                last_name: {{ var3 }},
                email: {{ var5 }},
                phone: {{ var10 }},
                brand_name: {{ brand_name }},
              },
              type: "track",
            }
          </pre>
        </div>
        <div>
          <div>Update (Track)</div>
          <div>URL : https://api.segment.io/v1/track</div>
          <div>Method : POST</div>
          <pre class="mt-3">
            {
              userId: {{ user_id }},
              context: {
                ip: "24.5.68.47",
              },
              timestamp: "2012-12-02T00:30:12.984Z",
              event: "Update",
              properties: {
                first_name: {{ var2 }},
                last_name: {{ var3 }},
                email: {{ var5 }},
                phone: {{ var10 }},
                brand_name: {{ brand_name }},
              },
              type: "track",
            }
          </pre>
        </div>
        <div>
          <div>Point (Identify)</div>
          <div>URL : https://api.segment.io/v1/identify</div>
          <div>Method : POST</div>
          <pre class="mt-3">
            {
              userId: {{ user_id }},
              context: {
                ip: "24.5.68.47",
              },
              timestamp: "2012-12-02T00:30:12.984Z",
              traits: {
                brand_name: {{ brand_name }},
                email: {{ var5 }},
                phone: {{ var10 }},
                birthday: "2023-01-18T00:00:00.000Z",
                registerdate: "2023-01-18T00:00:00.000Z",
                point: 10,
              },
              type: "identify",
            }
        </pre
          >
        </div>
        <div>
          <div>Point Earn (Track)</div>
          <div>URL : https://api.segment.io/v1/track</div>
          <div>Method : POST</div>
          <pre class="mt-3">
            {
              userId: {{ user_id }},
              context: {
                ip: "24.5.68.47",
              },
              timestamp: "2012-12-02T00:30:12.984Z",
              event: "Point Earn",
              properties: {
                email: {{ var5 }},
                phone: {{ var10 }},
                brand_name: {{ brand_name }},
                point_earn: 10,
              },
              type: "Track",
            }
          </pre>
        </div>
        <div>
          <div>Point Redeem (Track)</div>
          <div>URL : https://api.segment.io/v1/track</div>
          <div>Method : POST</div>
          <pre class="mt-3">
            {
              userId: {{ user_id }},
              context: {
                ip: "24.5.68.47",
              },
              timestamp: "2012-12-02T00:30:12.984Z",
              event: "Point Redeem",
              properties: {
                email: {{ var5 }},
                phone: {{ var10 }},
                brand_name: {{ brand_name }},
                point_redeem: 10,
              },
              type: "Track",
            }
          </pre>
        </div>
        <div>
          <div>Point Void (Track)</div>
          <div>URL : https://api.segment.io/v1/track</div>
          <div>Method : POST</div>
          <pre class="mt-3">
            {
              userId: {{ user_id }},
              context: {
                ip: "24.5.68.47",
              },
              timestamp: "2012-12-02T00:30:12.984Z",
              event: "Point Void",
              properties: {
                email: {{ var5 }},
                phone: {{ var10 }},
                brand_name: {{ brand_name }},
                point_void: 10,
              },
              type: "Track",
            }
          </pre>
        </div>
        <div>
          <div>Point Return (Track)</div>
          <div>URL : https://api.segment.io/v1/track</div>
          <div>Method : POST</div>
          <pre class="mt-3">
            {
              userId: {{ user_id }},
              context: {
                ip: "24.5.68.47",
              },
              timestamp: "2012-12-02T00:30:12.984Z",
              event: "Point Return",
              properties: {
                email: {{ var5 }},
                phone: {{ var10 }},
                brand_name: {{ brand_name }},
                point_return: 10,
              },
              type: "Track",
            }
          </pre>
        </div>
        <div>
          <div>Transaction (Track)</div>
          <div>URL : https://api.segment.io/v1/track</div>
          <div>Method : POST</div>
          <pre class="mt-3">
            {
              userId: {{ user_id }},
              context: {
                ip: "24.5.68.47",
              },
              timestamp: "2012-12-02T00:30:12.984Z",
              event: "Purchase",
              properties: {
                email: {{ var5 }},
                phone: {{ var10 }},
                brand_name: {{ brand_name }},
                grand_total: 20000,
                invoice_no: "001/123",
                total_quantity: 3,
                discount: 100,
                coupon_code: "FREEGIFT",
                action: "placed/void/return",
              },
              type: "track",
            }
          </pre>
        </div>
        <div>
          <div>Purchase item (track) (1 Payload per Item)</div>
          <div>URL : https://api.segment.io/v1/track</div>
          <div>Method : POST</div>
          <pre class="mt-3">
            {
              userId: {{ user_id }},
              timestamp: "2012-12-02T00:30:12.984Z",
              event: "Purchase Item",
              properties: {
                invoice_no: "001/123",
                sku: "3380810190105",
                quantity: 1,
                unit_price: 2950,
                total_price: 1950,
                discount: 1000,
                action: "placed/void/return",
              },
              type: "track",
            }
          </pre>
        </div>
      </template>
      <template v-else>
        <div>
          <div>When register</div>
          <div>URL : {{ url }}/api/d/v1/customer/consent/cmgbrand</div>
          <div>Method : POST</div>
          <pre class="mt-3">
        {   
            "reference" : {{ var11 }} 
            "firstName" : {{ var2 }}, 
            "lastName" : {{ var3 }}, 
            "birthday" : {{ var4 }}, 
            "email" : {{ var5 }}, 
            "telephone" : {{ var10 }}, 
            "gender" : {{ var6 }}, 
            "province" : {{ var7 }}, 
            "socialId" : "", 
            "cmgBrandCode" : {{ var8 }}, 
            "channelId" : {{ var12 }}, 
            "PlantCode" : "", 
            "isConsentDirect" : {{ var9 }}'
            "consentTime" : null, 
        }
     
      </pre
          >
        </div>
        <div>
          <div>When Update Profile</div>
          <div>URL : {{ url }}/api/d/v1/customer/update</div>
          <div>Method : PUT</div>
          <pre class="mt-3">
        {   
            "id" : {{ var1 }} 
            "firstName" : {{ var2 }}, 
            "lastName" : {{ var3 }}, 
            "birthday" : {{ var4 }}, 
            "email" : {{ var5 }}, 
            "telephone" : {{ var10 }}, 
            "gender" : {{ var6 }}, 
            "province" : {{ var7 }}, 
            "socialId" : "", 
            "cmgBrandCode" : {{ var8 }}, 
            "isConsentDirect" : {{ var9 }}'
        }
     
      </pre
          >
        </div>
      </template>
    </div>
    <FooterAction routePath="/setting/plugins" :hideSubmit="true" />
  </div>
</template>

<script>
export default {
  data() {
    return {
      var1: "{{ pdpa id }}",
      var2: "{{ first name }}",
      var3: "{{ last name }}",
      var4: "{{ birthday }}",
      var5: "{{ email }}",
      var6: "{{ gender }}",
      var7: "{{ province }}",
      var8: "{{ brand code }}",
      var9: "{{ Consent status }}",
      var10: "{{ telephone}}",
      var11: "{{ member id }}",
      var12: "{{ channel id }}",
      brand_name: "{{ brand name }}",
      user_id: "{{ user_id }}",

      id: this.$route.params.id,
      data: [],
    };
  },
  created() {
    // this.getData();
  },
  methods: {
    async getData() {
      const resp = await this.$Axios(
        `${process.env.VUE_APP_API}/Plugin/GetById/${this.id}`
      );
      this.data = resp.data.detail;
    },
  },
};
</script>

<style>
pre {
  font-size: 14px;
}
</style>
